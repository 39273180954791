import React from 'react'
import Helmet from 'react-helmet'
import { Link, graphql } from 'gatsby'
import { themeGet } from 'styled-system'
import kebabCase from 'lodash/kebabCase'
import Layout from '../layouts'
import styled from 'styled-components'
import {PostLink} from "../components/Link"
import { Post, PostHeader, PostDescription,  PostDate, Thumbnail } from "../components/Post"
import Hero from "../components/Hero"
import FilterCategory from "../components/Popup/FilterCategory"
import Text from "../components/Text"

const _ = require('lodash')

const Category = Text.extend`
  border: none;
  border-bottom: transparent solid 2px;
  cursor: pointer;
  transition: all 0.2s ease;
  color: ${themeGet('colors.contrast.3')};
  &:hover {
    border-color: ${themeGet('colors.contrast.0')};
  }
`.withComponent('span')

const PageHeader = styled.h1`
  font-size: ${themeGet('headings.9')};
  margin-top: 0;

  @media (max-width: ${themeGet('breakpoints.4')}) {
    font-size: ${themeGet('headings.8')};
  }
  @media (max-width: ${themeGet('breakpoints.2')}) {
    font-size: ${themeGet('headings.6')};
  }
`

const BlogList = styled.div`
  align-items: flex-start;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(auto, auto);
  grid-column-gap: 2em;
  grid-row-gap: 5.5em;
  margin: 0 auto;
  position: relative;
  @media (max-width: ${themeGet('breakpoints.2')}) { 
    grid-column-gap: 5em;
    grid-template-columns: repeat(1, 1fr);
  }
`

const FirstBlock = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  grid-column: 1 / -1;
  justify-content: space-between;
  width: 100%;
  @media (max-width: ${themeGet('breakpoints.2')}) { 
    margin: auto;
    grid-column-gap: 5em;
    & div {
      width: 100%;
    }
  }
`
const SecondBlock = styled.div`
  align-items: flex-start;
  display: grid;
  grid-column: 1 / -1;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 2em;
  position: relative;
  @media (max-width: ${themeGet('breakpoints.2')}) { 
    grid-column-gap: 5em;
    grid-template-columns: repeat(1, 1fr);
  }
`

const ChangeView = styled.div `
    align-items: flex-start;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin: 2em 0;
`

const Filter = styled.div`
  font-size: ${themeGet('fontSizes.5')};
  font-family: "GraphikMedium";
  grid-column: 1 / -2;

  & select {
    padding-left: 0.5em;
      background: white;
      border: 0px;
      -webkit-appearance: none;
  }
`

const ListChange = styled.div`
  text-align: right;
  padding: 0.6em 0;
`

const ChangeButton = styled.span `
  padding-left: 2em;
  color: gray;
`

const ListLayout = styled.div`
  & h1 {
    margin: 1em 0 0;
  }

  & p {
    margin-top: 0;
  }
`

const frontmatter = {
  title: 'Articles',
  description: 'I write to organize and sharpen my mind. You can read the articles I’ve written in this section.'
}

class CategoryRoute extends React.Component {
  constructor(props) {
    super(props)

      this.state = {
        view: true
      };

      this.changeView = this.changeView.bind(this);
  }
  changeView(flag){
      this.setState({
        view: flag
      });
  }

  renderList() {
    const list = this.props.data.allMdx.edges;
    if(this.state.view){
      return(
        <BlogList>
          <FirstBlock key="0">
            <Thumbnail height="360px" width="60%" style={{ background: `url(${list[0].node.frontmatter.image}) center center`, backgroundSize: 'cover'}} alt="logo" />
            <Post width="calc(40% - 2em)" marginTop="0.5em">
              <PostLink to={list[0].node.fields.slug}>
                <PostHeader fontSize={7}> {list[0].node.frontmatter.title}</PostHeader>
                <PostDescription fontSize="20px" lineHeight="34px">{list[0].node.frontmatter.description}</PostDescription>
                <PostDate>{list[0].node.frontmatter.date}</PostDate>
              </PostLink>
            </Post>
          </FirstBlock>
          <SecondBlock>
            { this.renderSecondBlock() }
          </SecondBlock>
          {this.renderThirdBlock()}
        </BlogList>
      )
    } else {
      return list.map((article, i) => {
        return (
          <ListLayout key={i}>
            <PostLink to={article.node.fields.slug}>
              <PostHeader fontSize={"3.2em"}>{article.node.frontmatter.title}</PostHeader>
              <PostDate>{article.node.frontmatter.date}</PostDate>
            </PostLink>
          </ListLayout>
        )
        })
    }
    
  }

  renderSecondBlock() {
    const list = this.props.data.allMdx.edges;
    
    return list.map((article, i) => {
      if(i===1 || i===2) {
        return (
          <div key={i}>
            <Thumbnail height="272px" style={{ background: `url(${article.node.frontmatter.image}) center center`, backgroundSize: 'cover'}} alt="logo" />
            <Post>
              <PostLink to={article.node.fields.slug}>
                <PostHeader>{article.node.frontmatter.title}</PostHeader>
                <PostDescription>{article.node.frontmatter.description}</PostDescription>
                <PostDate>{article.node.frontmatter.date}</PostDate>
              </PostLink>
            </Post>
          </div>
        )
      }
    })
    
  }

  renderThirdBlock() {
    const list = this.props.data.allMdx.edges;
    
    return list.map((article, i) => {
      if(i !== 0 && i!==1 && i!==2) {
        return (
          <div key={i}>
            <Thumbnail style={{ background: `url(${article.node.frontmatter.image}) center center`, backgroundSize: 'cover'}} alt="logo" />
            <Post>
              <PostLink to={article.node.fields.slug}>
                <PostHeader>{article.node.frontmatter.title}</PostHeader>
                <PostDescription>{article.node.frontmatter.description}</PostDescription>
                <PostDate>{article.node.frontmatter.date}</PostDate>
              </PostLink>
            </Post>
          </div>
        )
      }
      })
    
  }

  render() {
    const posts = this.props.data.allMdx.edges
    
    const category = this.props.pageContext.category
    const title = this.props.data.site.siteMetadata.title
    const totalCount = this.props.data.allMdx.totalCount
    const categoryHeader = `${category}`

    const { edges:articles } = this.props.data.allMdx;

    let categories = []
    articles.forEach(edge => {
      if (_.get(edge, `node.frontmatter.categories`)) {
        categories = categories.concat(edge.node.frontmatter.categories)
      }
    })

    categories = _.uniq(categories)

    return (
      <Layout>
          <Helmet title={`${category} | ${title}`} />
          <Hero data={frontmatter} />
          <ChangeView>
            <Filter>
              <FilterCategory categories={categories}>
                <Category>{category}</Category>
              </FilterCategory>
            </Filter>
            <ListChange>
              <ChangeButton onClick={() => this.changeView(false)}><img src={this.state.view ? "/img/list-icon.png" : "/img/list-icon-checked.png"}/></ChangeButton>
              <ChangeButton onClick={() => this.changeView(true)}><img src={this.state.view ? "/img/block-icon-checked.png" : "/img/block-icon.png"}/></ChangeButton>
            </ListChange>
          </ChangeView>
          {this.renderList()}
      </Layout>
    )
  }
}

export default CategoryRoute

export const categoryPageQuery = graphql`
  query CategoryPage($category: String) {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(
      limit: 1000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { categories: { in: [$category] },templateKey: {eq: "article-post"} } }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            description
            date(formatString: "MMMM DD - YYYY")
            image
            tags
            categories 
          }
        }
      }
    }
  }
`
